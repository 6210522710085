import { SEARCH_HINTS_URL, SEARCH_CONTENT_URL, ADD_FAVORITE_URL, REMOVE_FAVORITE_URL } from './_constants';
/**
 * Calls API to get the search hints 
 * @param {any} searchTerm User-inputted search term
 * @param {any} language The language to pass into the API
 * @param {any} curatedContentIds The content IDs to pass into the API
 * @param {any} autoPopulationCriteria The criteria to pass into the API
 * @returns {Promise} A Promise to handle success or failure from caller
 */

export var getSearchHints = function getSearchHints(searchTerm, language) {
  var curatedContentIds = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var autoPopulationCriteria = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var hintsPerContentType = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  searchTerm = searchTerm.replace(/</g, '');
  var encodedTerm = encodeURIComponent(searchTerm);
  var searchHintsUrl; // Old SearchHints API call

  searchHintsUrl = SEARCH_HINTS_URL + "?searchterm=" + encodedTerm + "&language=" + language + "&format=json";
  if (autoPopulationCriteria != null) searchHintsUrl = searchHintsUrl + "&AutoPopulationCriteria=" + autoPopulationCriteria;
  if (curatedContentIds != null) searchHintsUrl = searchHintsUrl + "&curatedContentIds=" + curatedContentIds;
  if (hintsPerContentType) searchHintsUrl = searchHintsUrl + "&hintsPerContentType=true";
  return fetch(searchHintsUrl).then(function (response) {
    if (!response.ok) {
      return [];
    }

    return response.json();
  });
};
/**
 * Calls API to retrieve the results for a content list
 * @param {any} searchParams object of parameters needed for the search service
 * @returns {Promise} A Promise to handle success or failure from caller
 */

export var getContentListResults = function getContentListResults(searchParams) {
  return fetch(SEARCH_CONTENT_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Content-RelatedSearchPhrase': window.ContentBase ? window.ContentBase.RelatedContentSearchPhrase : '',
      'X-Content-Id': window.ContentBase ? window.ContentBase.ContentId : ''
    },
    body: JSON.stringify(searchParams)
  }).then(function (response) {
    if (!response.ok) throw Error(response.statusText);
    return response.json();
  });
};
/**
 * Calls API to add an item to a user's favorite
 * @param {any} contentId GUID of the content item to add
 * @return {Promise} A promise to handle success or failure from the caller
 */

export var addFavorite = function addFavorite(contentId) {
  return fetch(ADD_FAVORITE_URL + '?contentId=' + contentId, {
    method: 'PUT'
  }).then(function (response) {
    if (!response.ok) throw Error(response.statusText);
    return response.ok;
  });
};
/**
 * Calls API to remove an item from the user's favorite
 * @param {any} contentId GUID of the content item to remove
 * @return {Promise} A promise to handle success or failure from the caller
 */

export var removeFavorite = function removeFavorite(contentId) {
  return fetch(REMOVE_FAVORITE_URL + '?contentId=' + contentId, {
    method: 'DELETE'
  }).then(function (response) {
    if (!response.ok) throw Error(response.statusText);
    return response.ok;
  });
};