import { LOGOUT_URL } from './_constants';
/**
 * Posts to /api/logout to log out user
 * @returns {Promise} A promise to handle success or failure from caller.
 */

export var postLogout = function postLogout() {
  return fetch(LOGOUT_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(function (response) {
    if (!response.ok) throw Error(response.statusText);
    return response.json();
  });
};