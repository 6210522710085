export default {
  // Snippet from https://stackoverflow.com/questions/36170425/detect-click-outside-element#answer-42389266
  bind: function bind(el, binding, vnode) {
    el.handleOutsideEvent = function (event) {
      // check that click was outside of element and children
      if (!(el === event.target || el.contains(event.target))) {
        // call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };

    document.body.addEventListener('click', el.handleOutsideEvent);
    document.body.addEventListener('focusin', el.handleOutsideEvent);
  },
  unbind: function unbind(el) {
    document.body.removeEventListener('click', el.handleOutsideEvent);
    document.body.addEventListener('focusin', el.handleOutsideEvent);
  }
};