
export default (function (config, elementId) {
  return _initializeNavigation(elementId);
});

var _initializeNavigation = function _initializeNavigation(elementId) {
  var vm = {},
      isNavOpen = false,
      OPENED_CLASS = "opened";
  var rootElement = document.body;
  var navElement = rootElement.querySelector("#" + elementId);
  var toggleElement = rootElement.querySelector(".simpleNavToggle");
  var navChildLookup = navElement.querySelectorAll(".simpleNavItems > li.withChildren");
  var navChildren = [];

  for (var i = 0; i < navChildLookup.length; i++) {
    navChildren.push(_initializeChild(navChildLookup[i]));
  }

  ;

  vm.NavOpen = function () {
    isNavOpen = true;
    navElement.classList.add(OPENED_CLASS);
    toggleElement.classList.add(OPENED_CLASS);
  };

  vm.NavClose = function () {
    isNavOpen = false;
    navElement.classList.remove(OPENED_CLASS);
    toggleElement.classList.remove(OPENED_CLASS);
    navChildren.forEach(function (c) {
      c.MenuCollapse();
    });
  };

  vm.NavToggleClick = function (eventArgs) {
    // Toggle clicks should not propogate events up. This avoids close/open actions from occurring unexpectedly.            
    eventArgs.stopPropagation();

    if (isNavOpen) {
      vm.NavClose();
    } else {
      vm.NavOpen();
    }
  };

  toggleElement.addEventListener("click", vm.NavToggleClick);

  vm.MyAccountToggleClick = function (eventArgs) {
    // Toggle clicks should not propogate events up. This avoids close/open actions from occurring unexpectedly.            
    eventArgs.stopPropagation();

    if (isMyAccountOpen) {
      vm.MyAccountClose();
    } else {
      vm.MyAccountOpen();
      vm.NavClose();
    }
  }; // Click events outside of navigation should close the nav


  rootElement.addEventListener("click", function () {
    vm.NavClose();
  });
  return vm;
};

var _initializeChild = function _initializeChild(navChildElement, mobileMenuOpened) {
  var vm = {},
      isOpen = false,
      MENU_EXPANDED_CLASS = "expanded",
      // Used for hovering in desktop view
  COLLAPSE_MENU_EVENT = "collapse_menu_event";
  if (!navChildElement || navChildElement.length === 0) navChildElement = document.createElement("ul");

  vm.MenuExpand = function () {
    isOpen = true;
    navChildElement.classList.add(MENU_EXPANDED_CLASS);
  };

  vm.MenuCollapse = function () {
    isOpen = false;
    navChildElement.classList.remove(MENU_EXPANDED_CLASS);
  };

  vm.OnToggleDropdown = function (eventArgs) {
    eventArgs.stopPropagation();

    if (isOpen) {
      vm.MenuCollapse();
    } else {
      var event = document.createEvent('Event');
      event.initEvent(COLLAPSE_MENU_EVENT, true, true);
      document.dispatchEvent(event);
      vm.MenuExpand();
    }
  }; // Listen to close menu event


  document.addEventListener(COLLAPSE_MENU_EVENT, vm.MenuCollapse); // Links should not propogate events up. This avoids close/open actions from occurring unexpectedly.

  var navElementAnchors = navChildElement.querySelectorAll("a");

  for (var i = 0; i < navElementAnchors.length; i++) {
    navElementAnchors[i].addEventListener("click", function (eventArgs) {
      eventArgs.stopPropagation();
    });
  } // Disable main navigation links only in desktop


  var mainNavElementAnchors = navChildElement.querySelectorAll("a.mainLink");

  for (var _i = 0; _i < mainNavElementAnchors.length; _i++) {
    mainNavElementAnchors[_i].addEventListener("click", function (eventArgs) {
      var mobileMenuOpened = document.querySelector("nav.opened") == null ? false : true;

      if (!mobileMenuOpened) {
        eventArgs.preventDefault();
        vm.OnToggleDropdown(eventArgs);
      }
    });
  } // For mobile menu expand/collapse sub menu icon


  navChildElement.addEventListener("click", vm.OnToggleDropdown);
  return vm;
};