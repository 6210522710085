var FOCUSABLE_SELECTOR = ['button', '[href]', 'input', 'select', 'textarea', '[tabindex]'].map(function (sel) {
  return sel + ':not([tabindex="-1"])';
}).join(',');
/**
 * Gets all of the focusable elements within a container.
 * @param {Element} parentElement
 * @returns {NodeListOf<Element>}
 */

exports.getFocusableElements = function (parentElement) {
  return parentElement && parentElement.querySelectorAll(FOCUSABLE_SELECTOR);
};