var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "categoryTile", class: _vm.containerClasses },
    [
      _c(
        "div",
        { staticClass: "headingContainer", style: _vm.headingAlignment },
        [
          _vm.settings.displayTitle
            ? _c("h2", { staticClass: "headingLarge" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.settings.title) + "\n        "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.settings.displayDescription
            ? _c("p", {
                staticClass: "descriptionContainer",
                domProps: { innerHTML: _vm._s(_vm.settings.description) }
              })
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "tileContainer" }, [
        _c(
          "ul",
          { staticClass: "contentItemsWrapper" },
          _vm._l(_vm.contentItems, function(contentItem) {
            return _c(
              "li",
              { staticClass: "contentItem", style: _vm.tileSizeStyling },
              [
                _c(
                  "a",
                  {
                    staticClass: "itemLink",
                    attrs: {
                      href: contentItem.itemUrl,
                      target: contentItem.link.target
                    },
                    on: {
                      click: function($event) {
                        _vm.$emit("content-item-click-event", contentItem)
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "itemImage",
                      attrs: {
                        src: contentItem.standardImages.standardImageUrl,
                        alt: ""
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "itemTitle" }, [
                      _vm._v(_vm._s(contentItem.title))
                    ])
                  ]
                )
              ]
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }