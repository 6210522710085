import { cookie } from '@Shared/Common';
export var toPascalCase = function toPascalCase(stringValue) {
  return stringValue == null ? null : stringValue.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};
export var setRecentSearchTermCookie = function setRecentSearchTermCookie(searchTerm) {
  if (searchTerm == null || searchTerm == '' || hasPrivacyOptOut()) return;
  searchTerm = searchTerm.trim().toLowerCase();
  var recentSearchTerms = cookie.getCookie('RecentSearchTerms');

  if (!recentSearchTerms) {
    cookie.setCookie('RecentSearchTerms', JSON.stringify([searchTerm]), 365);
  } else {
    var recentSearchTermsArray = JSON.parse(recentSearchTerms);

    if (!recentSearchTermsArray.includes(searchTerm)) {
      if (recentSearchTermsArray.length >= 3) recentSearchTermsArray = recentSearchTermsArray.slice(1);
      recentSearchTermsArray.push(searchTerm);
      cookie.setCookie('RecentSearchTerms', JSON.stringify(recentSearchTermsArray), 365);
    }
  }
};
export var hasPrivacyOptOut = function hasPrivacyOptOut() {
  return typeof window.GeneralMills.getGlobalSetting === "function" && (window.GeneralMills.getGlobalSetting('userPrivacy_AnalyticsEnabled') === false || window.GeneralMills.getGlobalSetting('userPrivacy_FunctionalEnabled') === false);
};