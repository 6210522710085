var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ratingsAndReviewCount" }, [
    _c("span", { attrs: { "aria-label": _vm.averageRatingAriaLabel } }, [
      _c("span", {
        class: _vm.averageRatingClassNames,
        attrs: { "aria-hidden": "true" }
      })
    ]),
    _vm._v(" "),
    _c(
      "span",
      {
        staticClass: "ratingCount",
        attrs: { "aria-label": _vm.ratingCountAriaLabel }
      },
      [_vm._v("(" + _vm._s(_vm.contentItem.ratingCount) + ")")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }