import "@Styles/Shared/SearchNx/ContentListTemplates/carouselList_sm.less";
import "@Styles/Shared/SearchNx/ContentListTemplates/carouselList_md.less";
import "@Styles/Shared/SearchNx/ContentListTemplates/carouselList_lg.less";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import carouselBase from '@Shared/Common/Scripts/Components/carouselBase';
import favoriteButton from './Components/favoriteButton';
import ratingsAndReviewCount from './Components/ratingsAndReviewCount';
export default {
  components: {
    carouselBase: carouselBase,
    favoriteButton: favoriteButton,
    ratingsAndReviewCount: ratingsAndReviewCount
  },
  props: {
    config: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    results: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isAnonymous: {
      type: Boolean
    },
    isAnonWithFav: {
      type: Boolean
    }
  },
  data: function data() {
    return {
      settings: this.config ? this.config : {},
      contentItems: this.results ? this.results : [],
      visibleItemsCount: 3
    };
  },
  computed: {
    containerClasses: function containerClasses() {
      return [this.settings.backgroundColor ? this.settings.backgroundColor : '', this.settings.desktopTextPlacement ? this.settings.desktopTextPlacement : ''];
    },
    headingAlignment: function headingAlignment() {
      return {
        'text-align': this.settings.headingAlignment ? this.settings.headingAlignment : 'left'
      };
    }
  },
  mounted: function mounted() {
    var mediumScreenSize = this.settings.mediumScreenMaxWidth.replace(/\D/g, '');

    if (window.innerWidth > mediumScreenSize && this.settings.desktopTextPlacement !== 'inline') {
      this.visibleItemsCount = 4;
    }
  }
};