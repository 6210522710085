import { getDefaultObserverTarget, isIntersectionObserverSupported } from './_util';
/**
 * An intersection observer to be used for items docked to the bottom of a page.
 * Add intersection handlers to execute when the intersection occurs.
 * @param {any} config
 */

export default function (config) {
  var external = {};
  var internal = {
    isFirstIntersect: true,
    rdpTargetClass: 'recipeIngredients',
    intersectionHandlers: []
  };

  if (config && config.testMode === true) {
    external.internal = internal;
  }

  internal.initialize = function () {
    if (!isIntersectionObserverSupported()) {
      return;
    }

    var rdpTarget = document.getElementsByClassName(internal.rdpTargetClass);
    var isRdp = rdpTarget && rdpTarget.length > 0;

    if (isRdp) {
      var options = {
        root: null,
        //viewport is the root
        rootMargin: "0px 0px 0px 0px",
        threshold: [0.5]
      };
      var observer = new window.IntersectionObserver(internal.handleRdpIntersectionEvent, options);
      observer.observe(rdpTarget[0]);
    } else {
      var _options = {
        root: null,
        //viewport is the root
        rootMargin: "1000px 0px 0px 0px",
        threshold: [0]
      };

      var _observer = new window.IntersectionObserver(internal.handleNonRdpIntersectionEvent, _options);

      _observer.observe(getDefaultObserverTarget());
    }
  };

  internal.handleRdpIntersectionEvent = function (intersectionEntries) {
    intersectionEntries.forEach(function (entry) {
      // Intersection event will fire once at page load -- this is expected
      if (internal.isFirstIntersect) {
        internal.isFirstIntersect = false; // If we are below the target on first fire, toggle the toolbar on

        if (entry.boundingClientRect.bottom < entry.rootBounds.bottom) internal.executeIntersectionHandlers();
        return;
      } // only acting on target's top intersection (there will be 2, bottom comparison will skip second intersection)


      if (entry.boundingClientRect.bottom < entry.rootBounds.bottom) return;
      internal.executeIntersectionHandlers();
    });
  };

  internal.handleNonRdpIntersectionEvent = function (intersectionEntries) {
    intersectionEntries.forEach(function (entry) {
      //checking boundingClientRect to make sure we can account for the case where the user scrolls up
      //When scrolling up, the boundingClientRect.Bottom will be within the range
      if (entry.intersectionRatio !== 1 || entry.boundingClientRect.bottom <= -800 && entry.boundingClientRect.bottom >= -1000) {
        internal.executeIntersectionHandlers();
      }
    });
  };

  internal.executeIntersectionHandlers = function () {
    for (var i in internal.intersectionHandlers) {
      internal.intersectionHandlers[i]();
    }
  };
  /**
   * Adds an handler function to be executed when intersection occurs.
   * @param {function} handler
   */


  external.addIntersectionHandler = function (handler) {
    if (typeof handler === 'function') {
      internal.intersectionHandlers.push(handler);
    }
  };

  internal.initialize();
  return external;
}
;