import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
export default {
  inserted: function inserted(el, binding) {
    function loadImages() {
      var images = document.querySelectorAll("#" + el.id + " img");

      _toConsumableArray(images).forEach(function (img) {
        img.src = img.dataset.src;
      });
    }

    function handleIntersect(entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          loadImages();
          observer.unobserve(el);
        }
      });
    }

    function createObserver() {
      var buffer = binding.value == undefined || isNaN(binding.value) ? 200 : binding.value;
      var observerOptions = {
        root: null,
        //viewport
        rootMargin: buffer + "px 0px",
        threshold: 0.01
      };
      var observer = new IntersectionObserver(handleIntersect, observerOptions);
      observer.observe(el);
    }

    var isAuto = binding.modifiers && binding.modifiers.auto;
    var intersectionSupported = !!(window.IntersectionObserver && window.IntersectionObserverEntry); // If Intersection Observer is not supported, immediately load images

    if (intersectionSupported && !isAuto) {
      createObserver();
    } else {
      loadImages();
    }
  }
};