import "@Styles/Controls/ContentHeader/Templates/HeroTemplate/heroTemplate_sm.less";
import "@Styles/Controls/ContentHeader/Templates/HeroTemplate/heroTemplate_md.less";
import "@Styles/Controls/ContentHeader/Templates/HeroTemplate/heroTemplate_lg.less";
export default {
  data: function data() {
    return {
      settings: {},
      activeBackgroundImage: '',
      justifyContentValue: ''
    };
  },
  computed: {
    containerStyling: function containerStyling() {
      return {
        backgroundImage: this.activeBackgroundImage,
        justifyContent: this.justifyContentValue
      };
    },
    contentStyling: function contentStyling() {
      return {
        backgroundColor: this.settings.contentBackgroundColor ? this.settings.contentBackgroundColor : '',
        textAlign: this.justifyContentValue === 'center' ? 'center' : 'left'
      };
    },
    textColorTheme: function textColorTheme() {
      return this.settings.textColorTheme ? this.settings.textColorTheme : '';
    }
  },
  destroyed: function destroyed() {
    window.removeEventListener('resize', this.windowResizeHandler);
  },
  methods: {
    init: function init(config) {
      if (config) {
        this.settings = config || {}; // initial call and event listener for background image

        this.windowResizeHandler();
        window.addEventListener('resize', this.windowResizeHandler);
      }
    },
    windowResizeHandler: function windowResizeHandler(e) {
      var smallScreenSize = this.settings.smallScreenMaxWidth.replace(/\D/g, '');
      var mediumScreenSize = this.settings.mediumScreenMaxWidth.replace(/\D/g, '');

      if (window.innerWidth < smallScreenSize) {
        this.activeBackgroundImage = 'url("' + this.settings.smallImageUrl + '")';
        this.justifyContentValue = this.settings.textPlacementMobile;
      } else if (smallScreenSize < window.innerWidth && window.innerWidth < mediumScreenSize) {
        this.activeBackgroundImage = 'url("' + this.settings.mediumImageUrl + '")';
        this.justifyContentValue = this.settings.textPlacementMobile;
      } else {
        this.activeBackgroundImage = 'url("' + this.settings.heroImageUrl + '")';
        this.justifyContentValue = this.settings.textPlacementDesktop;
      }
    },
    onContentClick: function onContentClick() {
      GeneralMills.Analytics && GeneralMills.Analytics.raiseEvent({
        EventType: 'ContentResult',
        ContentListName: this.settings.heading,
        TemplateName: "ContentHeader",
        LinkUrl: this.settings.ctaButtonLink
      });
    }
  }
};