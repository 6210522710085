/**
 * Grab an element at the top of the page to track user scroll status.
 * In most cases the ASP.NET generated <div class='aspNetHidden'> will be at the top of the body,
 * If not grab the first div
 * @returns {Element}
 */
exports.getDefaultObserverTarget = function () {
  //grab an element at the top of the page to track user scroll status
  //in most cases the ASP.NET generated <div class='aspNetHidden'> will be at the top of the body, 
  //if not grab the first div
  return document.querySelector(".aspNetHidden") || document.querySelector("div") || document.body;
};
/**
 * Returns true if the Intersection Observer API is supported in the browser.
 * @returns {boolean}
 */


exports.isIntersectionObserverSupported = function () {
  return !!(window.IntersectionObserver && window.IntersectionObserverEntry);
};