import "@Styles/Controls/ContentBuilder/featuredContent_sm.less";
import "@Styles/Controls/ContentBuilder/featuredContent_md.less";
import "@Styles/Controls/ContentBuilder/featuredContent_lg.less";
export default {
  data: function data() {
    return {
      settings: {}
    };
  },
  methods: {
    init: function init(config) {
      if (config) {
        this.settings = config.settings;
      }
    },
    analyticEvent: function analyticEvent() {
      GeneralMills.Analytics.raiseEvent({
        EventType: 'ContentResult',
        LinkUrl: this.settings.linkUrl,
        ContentListName: this.settings.headerText,
        TemplateName: 'Featured Content'
      });
    }
  }
};