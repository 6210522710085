var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "carouselContainer" }, [
    _c("button", {
      staticClass: "navigationButton previousButton",
      class: _vm.previousButtonClass,
      attrs: {
        type: "button",
        "aria-label": _vm.leftButtonLabel,
        disabled: !_vm.showPreviousButton
      },
      on: { click: _vm.previousButtonClick }
    }),
    _vm._v(" "),
    _c("button", {
      staticClass: "navigationButton nextButton",
      class: _vm.nextButtonClass,
      attrs: {
        type: "button",
        "aria-label": _vm.rightButtonLabel,
        disabled: !_vm.showNextButton
      },
      on: { click: _vm.nextButtonClick }
    }),
    _vm._v(" "),
    _c(
      "span",
      {
        staticClass: "visuallyHidden",
        attrs: { "aria-live": "polite", "aria-atomic": "true" }
      },
      [_vm._v(_vm._s(_vm.ariaLiveText))]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "itemsWrapper" }, [
      _c(
        "ul",
        { ref: "itemsInner", staticClass: "itemsInner" },
        _vm._l(_vm.items, function(item, index) {
          return _c(
            "li",
            {
              key: index,
              ref: "item" + index,
              refInFor: true,
              staticClass: "item"
            },
            [_vm._t("content", null, { item: item, index: index })],
            2
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.includeScrollIndicator
        ? _c(
            "ul",
            { ref: "itemsIndicator", staticClass: "itemsIndicatorInner" },
            _vm._l(_vm.items, function(item, index) {
              return _c("li", {
                ref: "carouselIndicatorItem" + index,
                refInFor: true,
                staticClass: "carouselIndicator",
                attrs: { id: "carouselIndicatorItem" + index }
              })
            }),
            0
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }