import "@Styles/Global/_forms_sm.less";
import "@Styles/Global/_globalCore_lg.less";
import "@Styles/Global/_globalCore_md.less";
import "@Styles/Global/_globalCore_sm.less";
import "@Styles/Global/_Import/_icons.less";
import "@Styles/Global/_Import/_mixins-usage.less";
import "@Styles/Global/_Import/TBSP/_mixins.less";
import "@Styles/Global/TBSP/_fonts.less";
import "@Styles/Global/TBSP/_globalCore_sm.less";
import "@Styles/Structure/Footer/_footer_lg.less";
import "@Styles/Structure/Footer/_footer_sm.less";
import "@Styles/Structure/Header/_header_lg.less";
import "@Styles/Structure/Header/_header_sm.less";
import "@Styles/Structure/Header/Pando/_header_lg.less";
import "@Styles/Structure/Header/Pando/_header_sm.less";
import "@Styles/Structure/Footer/TBSP/_footer_sm.less";
import "@Styles/Structure/Header/TBSP/_header_sm.less";
import "@Styles/Global/_globalCore_md.less";
import "@Styles/Global/_globalCore_lg.less";
import "@Styles/Structure/Footer/_footer_lg.less";
import "@Styles/Structure/Header/_header_lg.less";
import "@Styles/Structure/Header/Pando/_header_lg.less";
import heroTemplate from '../Controls/ContentHeader/Templates/heroTemplate';
import searchBox from '@Shared/SearchNX/Scripts/searchBox';
import simpleNav from '../Controls/SimpleNav/simpleNav';
import targetedNav from '@Shared/SimpleNavigation/Scripts/targetedNav';
import userLinks from "../Controls/UserLinks/userLinks";
import channelLinks from "@Shared/Contrib/Scripts/channelLinks";
import ratingAndReviewCounts from '@Shared/ConsumerGeneratedContent/Scripts/ratingAndReviewCounts';
import featuredContent from '../Controls/ContentBuilder/featuredContent';
import contentList from '@Shared/SearchNx/Scripts/contentList';
import verticalVideoList from '../Controls/ContentBuilder/verticalVideoList';

var doubleClick = function doubleClick() {
  return import(
  /* webpackChunkName: 'dblClick' */
  '@Shared/DoubleClick/Scripts/doubleClickContainer');
};

var sovrn = function sovrn() {
  return import(
  /* webpackChunkName: 'sovrn' */
  '@Shared/DoubleClick/Scripts/sovrnContainer');
};

var newsletterCta = function newsletterCta() {
  return import(
  /* webpackChunkName: 'newsletterCta' */
  '@Shared/MyAccount/Scripts/_newsletterCta');
};

var cgc = function cgc() {
  return import(
  /* webpackChunkName: 'cgc' */
  '@Shared/ConsumerGeneratedContent/Scripts/consumerGeneratedContent');
};

var cgcRedesign = function cgcRedesign() {
  return import(
  /* webpackChunkName: 'cgcRedesign' */
  '@Shared/ConsumerGeneratedContent/Scripts/consumerGeneratedContentRedesign');
};

var thirdPartyScript = function thirdPartyScript() {
  return import(
  /* webpackChunkName: "thirdPartyScript" */
  '../Controls/ThirdPartyScript/thirdPartyScript');
};

var thirdPartyAdProviderContainer = function thirdPartyAdProviderContainer() {
  return import(
  /* webpackChunkName: "thirdPartyProviderContainer" */
  '@Shared/DoubleClick/Scripts/thirdPartyProviderContainer');
};

var oneTrust = function oneTrust() {
  return import(
  /* webpackChunkName: "oneTrust" */
  '../Controls/OneTrust/_oneTrust');
};

(function () {
  GeneralMills.PandoSites.RegisterModules({
    heroTemplate: heroTemplate,
    searchBox: searchBox,
    simpleNav: simpleNav,
    targetedNav: targetedNav,
    doubleClick: doubleClick,
    sovrn: sovrn,
    userLinks: userLinks,
    channelLinks: channelLinks,
    newsletterCta: newsletterCta,
    ratingAndReviewCounts: ratingAndReviewCounts,
    consumerGeneratedContent: cgc,
    consumerGeneratedContentRedesign: cgcRedesign,
    thirdPartyScript: thirdPartyScript,
    oneTrust: oneTrust,
    contentList: contentList,
    featuredContent: featuredContent,
    verticalVideoList: verticalVideoList,
    thirdPartyProvider: thirdPartyAdProviderContainer
  });
})();