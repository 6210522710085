import "@Styles/Shared/Common/Components/carouselBase_sm.less";
import "@Styles/Shared/Common/Components/carouselBase_md.less";
import "@Styles/Shared/Common/Components/carouselBase_lg.less";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    items: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    visibleItemsCount: {
      type: Number
    },
    leftButtonLabel: {
      type: String
    },
    rightButtonLabel: {
      type: String
    },
    ariaLiveLabelFormat: {
      type: String
    },
    includeScrollIndicator: {
      type: Boolean
    }
  },
  data: function data() {
    return {
      previousButtonObserver: {},
      nextButtonObserver: {},
      showPreviousButton: false,
      showNextButton: true,
      ariaLiveText: '',
      listStep: 0,
      listPosition: 0
    };
  },
  computed: {
    previousButtonClass: function previousButtonClass() {
      return this.showPreviousButton ? '' : 'hidden';
    },
    nextButtonClass: function nextButtonClass() {
      return this.showNextButton ? '' : 'hidden';
    }
  },
  mounted: function mounted() {
    // Calculate the step of items and attach to resize listener
    this.$_setStep();
    window.addEventListener('resize', this.$_setStep); // Account for scenarios where a user might swipe scroll and use the carousel buttons,
    // which would cause inconsistencies in listPosition without this event listener

    this.$refs.itemsInner.addEventListener('scrollend', this.$_scrollEventListener); // Account for IOS devices since scrollend isn't supported

    this.$refs.itemsInner.addEventListener('scroll', this.$_scrollEventListener); // Set up observers for navigation button visibility

    this.$_setButtonObservers();
  },
  destroyed: function destroyed() {
    window.removeEventListener('resize', this.$_setStep);
    this.$refs.itemsInner.removeEventListener('scrollend', this.$_scrollEventListener);
    this.$refs.itemsInner.removeEventListener('scroll', this.$_scrollEventListener);
    this.previousButtonObserver.unobserve(this.$refs['item0'][0]);
    this.nextButtonObserver.unobserve(this.$refs["item".concat(this.items.length - 1)][0]);
  },
  methods: {
    previousButtonClick: function previousButtonClick() {
      this.listPosition -= this.listStep;
      this.$_setAriaLiveText();
      this.$_scrollList();
    },
    nextButtonClick: function nextButtonClick() {
      this.listPosition += this.listStep;
      this.$_setAriaLiveText();
      this.$_scrollList();
    },
    $_setStep: function $_setStep() {
      var lastMargin = 10; // need to account for right margin of last item, not accounted for in scrollWidth

      var innerWidth = this.$refs.itemsInner.scrollWidth + lastMargin;
      var totalItems = this.items.length;
      this.listStep = innerWidth / totalItems;

      if (totalItems <= 1) {
        this.includeScrollIndicator = false;
      }
    },
    $_scrollEventListener: function $_scrollEventListener(event) {
      this.listPosition = event.currentTarget.scrollLeft;

      if (this.includeScrollIndicator) {
        this.$_setActiveIndicator();
      }
    },
    $_setActiveIndicator: function $_setActiveIndicator() {
      var currentItemIndex = this.listPosition / this.listStep; //Remove old activeItem class

      Array.from(this.$refs.itemsIndicator.children).forEach(function (element) {
        element.classList.remove("activeItem");
      }); //Add activeItem class to element

      this.$refs['carouselIndicatorItem' + Math.trunc(currentItemIndex)][0].classList.add("activeItem");
    },
    $_setButtonObservers: function $_setButtonObservers() {
      this.previousButtonObserver = new IntersectionObserver(this.$_setPreviousButtonVisibility, {
        root: this.$refs.itemsInner,
        threshold: 0.98
      });
      this.previousButtonObserver.observe(this.$refs['item0'][0]);
      this.nextButtonObserver = new IntersectionObserver(this.$_setNextButtonVisibility, {
        root: this.$refs.itemsInner,
        threshold: 0.98
      });
      this.nextButtonObserver.observe(this.$refs["item".concat(this.items.length - 1)][0]);
    },
    $_setPreviousButtonVisibility: function $_setPreviousButtonVisibility(entries) {
      if (this.items.length <= this.visibleItemsCount) {
        this.showPreviousButton = false;
      } else {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = entries[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var entry = _step.value;
            this.showPreviousButton = !entry.isIntersecting;
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }
    },
    $_setNextButtonVisibility: function $_setNextButtonVisibility(entries) {
      if (this.items.length <= this.visibleItemsCount) {
        this.showNextButton = false;
      } else {
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = entries[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var entry = _step2.value;
            this.showNextButton = !entry.isIntersecting;
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      }
    },
    $_setAriaLiveText: function $_setAriaLiveText() {
      if (this.ariaLiveLabelFormat) {
        // Set string values
        var firstItem = Math.round(this.listPosition / this.listStep) + 1;
        var secondItem = firstItem + this.visibleItemsCount - 1;
        var totalCount = this.items.length; // Replace formatted string with values

        var text = this.ariaLiveLabelFormat.replace('{0}', firstItem);
        text = text.replace('{1}', secondItem);
        text = text.replace('{2}', totalCount);
        this.ariaLiveText = text;
      }
    },
    $_scrollList: function $_scrollList() {
      this.$refs.itemsInner.scroll({
        left: this.listPosition,
        behavior: 'smooth'
      });
    }
  }
};