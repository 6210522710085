import "@Styles/Shared/SearchNx/ContentListTemplates/Components/ratingsAndReviewCount_sm.less";
//
//
//
//
//
//
//
//
//
export default {
  props: {
    config: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      settings: this.config ? this.config : {},
      contentItem: this.item ? this.item : {}
    };
  },
  computed: {
    averageRatingAriaLabel: function averageRatingAriaLabel() {
      return this.contentItem.averageRatingLabel ? this.contentItem.averageRatingLabel + ' stars' : '';
    },
    averageRatingClassNames: function averageRatingClassNames() {
      return ['stars', this.contentItem.averageRatingClass ? 'star-' + this.contentItem.averageRatingClass : ''];
    },
    ratingCountAriaLabel: function ratingCountAriaLabel() {
      return this.contentItem.ratingCount ? this.contentItem.ratingCount + ' ratings' : '';
    }
  }
};