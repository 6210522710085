var render, staticRenderFns
import script from "./channelLinks.vue?vue&type=script&lang=js&"
export * from "./channelLinks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\actions-runner\\_work\\red.PandoSites\\red.PandoSites\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('1d226d74', component.options)
    } else {
      api.reload('1d226d74', component.options)
    }
    
  }
}
component.options.__file = "PandoSites.Web/Shared/Contrib/Scripts/channelLinks.vue"
export default component.exports