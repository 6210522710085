var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "carouselList", class: _vm.containerClasses },
    [
      _c(
        "div",
        { staticClass: "headingContainer", style: _vm.headingAlignment },
        [
          _vm.settings.displayTitle
            ? _c("h2", { staticClass: "headingLarge" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.settings.title) + "\n        "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.settings.displayDescription
            ? _c("p", {
                staticClass: "descriptionContainer",
                domProps: { innerHTML: _vm._s(_vm.settings.description) }
              })
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("carousel-base", {
        attrs: {
          items: _vm.contentItems,
          "visible-items-count": _vm.visibleItemsCount,
          "left-button-label": _vm.settings.scrollLeftButtonLabel,
          "right-button-label": _vm.settings.scrollRightButtonLabel,
          "aria-live-label-format": _vm.settings.ariaLiveLabelFormat
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function(props) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a",
                    {
                      staticClass: "itemLink",
                      attrs: {
                        href: props.item.itemUrl,
                        target: props.item.link.target
                      },
                      on: {
                        click: function($event) {
                          _vm.$emit("content-item-click-event", props.item)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "itemImage" }, [
                        _c("picture", [
                          _c("source", {
                            attrs: {
                              srcset: props.item.standardImages.largeImageUrl,
                              media: "(min-width: 1000px)"
                            }
                          }),
                          _vm._v(" "),
                          _c("source", {
                            attrs: {
                              srcset: props.item.standardImages.mediumImageUrl,
                              media: "(min-width: 767px)"
                            }
                          }),
                          _vm._v(" "),
                          _c("source", {
                            attrs: {
                              srcset: props.item.standardImages.smallImageUrl
                            }
                          }),
                          _vm._v(" "),
                          _c("img", {
                            attrs: {
                              src: props.item.standardImages.standardImageUrl,
                              alt: ""
                            }
                          })
                        ]),
                        _vm._v(" "),
                        props.item.tag
                          ? _c("span", { staticClass: "itemTag" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(props.item.tag) +
                                  "\n                    "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "itemInfo" },
                        [
                          _c("div", { staticClass: "itemTitle" }, [
                            _vm._v(_vm._s(props.item.title))
                          ]),
                          _vm._v(" "),
                          _vm.settings.displayRatings
                            ? _c("ratings-and-review-count", {
                                attrs: {
                                  config: _vm.settings,
                                  item: props.item
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.settings.displayFavoriteButton
                    ? _c("favorite-button", {
                        attrs: {
                          config: _vm.settings,
                          item: props.item,
                          "is-anonymous": _vm.isAnonymous,
                          "is-anon-with-fav": _vm.isAnonWithFav
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }