import "@Styles/Controls/UserLinks/userLinks_sm.less";
import "@Styles/Controls/UserLinks/userLinks_lg.less";
import { ClickOutside } from '@Shared/Common';
import { postLogout } from './_api';
import { logMessage } from './_util';
export default {
  data: function data() {
    return {
      config: {},
      myAccountExpanded: false
    };
  },
  directives: {
    clickoutside: ClickOutside
  },
  methods: {
    init: function init(config) {
      this.config = config || {};
    },
    toggleMyAccountExpanded: function toggleMyAccountExpanded() {
      this.myAccountExpanded = !this.myAccountExpanded;
    },
    onClickOutside: function onClickOutside() {
      this.myAccountExpanded = false;
    },
    logout: function logout(event) {
      if (event) event.preventDefault();
      postLogout().then(function (returnUri) {
        window.location.assign(returnUri);
      }).catch(function (e) {
        logMessage(e.message);
      });
    }
  }
};