import "@Styles/Shared/SearchNx/ContentListTemplates/categoryTile_sm.less";
import "@Styles/Shared/SearchNx/ContentListTemplates/categoryTile_md.less";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    config: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    results: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isAnonymous: {
      type: Boolean
    }
  },
  data: function data() {
    return {
      settings: this.config ? this.config : {},
      contentItems: this.results ? this.results : []
    };
  },
  computed: {
    containerClasses: function containerClasses() {
      return [this.settings.backgroundColor ? this.settings.backgroundColor : '', this.settings.mobileLayout ? this.settings.mobileLayout : ''];
    },
    headingAlignment: function headingAlignment() {
      return {
        'text-align': this.settings.headingAlignment ? this.settings.headingAlignment : 'left'
      };
    },
    tileSizeStyling: function tileSizeStyling() {
      return {
        'width': this.settings.tileSize ? this.settings.tileSize : '86px'
      };
    }
  }
};