import "@Styles/Shared/Contrib/channelLinks_sm.less";
import "@Styles/Shared/Contrib/channelLinks_md.less";
import "@Styles/Shared/Contrib/channelLinks_lg.less";
export default {
  methods: {
    itemClick: function itemClick(event) {
      event.stopPropagation();
      var item = event.currentTarget;
      var data = this.getItemData(item);
      this.raiseAnalyticsEvent(data);
      var socialUrl = data.linkUrl;
      this.$_raiseSocialEvent(socialUrl);
    },
    getItemData: function getItemData(item) {
      var data = {};

      if (item) {
        data = {
          linkPosition: parseInt(item.getAttribute("data-position")) || '',
          linkUrl: item.href || '',
          linkTitle: item.innerText || ''
        };
      }

      return data;
    },
    raiseAnalyticsEvent: function raiseAnalyticsEvent(data) {
      if (!data.linkPosition || !data.linkUrl || !data.linkTitle) return;
      var event = {
        EventType: "ChannelLink",
        LinkPosition: data.linkPosition,
        LinkUrl: data.linkUrl,
        LinkTitle: data.linkTitle
      };
      if (GeneralMills.Analytics) GeneralMills.Analytics.raiseEvent(event);
    },
    $_raiseSocialEvent: function $_raiseSocialEvent(socialUrl) {
      if (this.$_getParentElement().className.indexOf("socialLinks") !== -1) {
        if (!socialUrl) return;
        var socialNetwork;
        socialUrl = socialUrl.toLowerCase();

        if (socialUrl.indexOf("facebook.com") > -1) {
          socialNetwork = 'Facebook';
        } else if (socialUrl.indexOf("pinterest.com") > -1) {
          socialNetwork = 'Pinterest';
        } else if (socialUrl.indexOf('twitter.com') > -1) {
          socialNetwork = 'Twitter';
        } else if (socialUrl.indexOf('youtube.com') > -1) {
          socialNetwork = 'YouTube';
        } else if (socialUrl.indexOf("instagram.com") > -1) {
          socialNetwork = 'Instagram';
        }

        if (socialNetwork) {
          GeneralMills.Analytics.raiseEvent({
            EventType: 'SocialFollow',
            SocialNetwork: socialNetwork
          });
        }
      }
    },
    $_getParentElement: function $_getParentElement() {
      /* istanbul ignore next */
      return this.$el.parentElement;
    }
  }
};