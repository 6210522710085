import "@Styles/Shared/SearchNx/ContentListTemplates/Components/favoriteButton_sm.less";
//
//
//
//
//
//
//
//
//
import { addFavorite, removeFavorite } from '../../_api';
import { toPascalCase } from '../../_util';
import { FAVORITE_COOKIE_NAME } from '../../_constants';
import { cookie } from '@Shared/Common';
export default {
  props: {
    config: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isAnonymous: {
      type: Boolean
    },
    isAnonWithFav: {
      type: Boolean
    }
  },
  data: function data() {
    return {
      settings: this.config ? this.config : {},
      contentItem: this.item ? this.item : {}
    };
  },
  computed: {
    buttonIcon: function buttonIcon() {
      return this.contentItem.isFavorite ? 'iconHeartFilled' : 'iconHeartEmpty';
    },
    buttonText: function buttonText() {
      return this.contentItem.isFavorite ? this.settings.favoritedButtonText : this.settings.defaultButtonText;
    },
    buttonAriaLabel: function buttonAriaLabel() {
      var staticText = this.contentItem.isFavorite ? this.settings.unfavoriteButtonLabel : this.settings.defaultButtonText;
      return staticText + ' ' + this.contentItem.title;
    }
  },
  created: function created() {
    if (this.contentItem.cookieAddFavorite) {
      this.$_addFavorite();
      delete this.contentItem.cookieAddFavorite;
    }
  },
  methods: {
    buttonClick: function buttonClick() {
      if (this.isAnonymous) {
        cookie.setCookie(FAVORITE_COOKIE_NAME, this.contentItem.id);
        this.$_handleRedirect();
      } else {
        if (!this.contentItem.isFavorite) {
          this.$_addFavorite();
        } else {
          this.$_removeFavorite();
        }
      }
    },
    $_handleRedirect: function $_handleRedirect() {
      var returnUrl = window.location.pathname;
      var url = this.settings.registrationUrl + '?returnUrl=' + encodeURIComponent(returnUrl) + '&isRegistrationFirstStep=false&RegAction=FAVORITE';

      if (this.isAnonWithFav) {
        url += '&value_prop=AnonFavorites';
      }

      window.location.assign(url);
    },
    $_addFavorite: function $_addFavorite() {
      var _this = this;

      addFavorite(this.contentItem.id).then(function (response) {
        if (response) {
          _this.contentItem.isFavorite = !_this.contentItem.isFavorite;
          GeneralMills.Analytics.raiseEvent({
            EventType: 'AddToFavorites',
            ContentId: _this.contentItem.id,
            ContentTitle: _this.contentItem.title,
            RecordType: toPascalCase(_this.contentItem.contentType),
            ContentType: toPascalCase(_this.contentItem.contentType)
          });
        }
      });
    },
    $_removeFavorite: function $_removeFavorite() {
      var _this2 = this;

      removeFavorite(this.contentItem.id).then(function (response) {
        if (response) {
          _this2.contentItem.isFavorite = !_this2.contentItem.isFavorite;
        }
      });
    }
  }
};