import "@Styles/Shared/SearchNx/searchBox_sm.less";
import "@Styles/Shared/SearchNx/TBSP/searchBox_sm.less";
import "@Styles/Shared/SearchNx/searchBox_lg.less";
import { setRecentSearchTermCookie } from './_util';

var searchhints = function searchhints() {
  return import(
  /* webpackChunkName: 'hints' */
  './searchHints');
};

var searchhintsRedesign = function searchhintsRedesign() {
  return import(
  /* webpackChunkName: 'hints' */
  './searchHintsRedesign');
};

export default {
  components: {
    'searchhints': searchhints,
    'searchhintsRedesign': searchhintsRedesign
  },
  data: function data() {
    return {
      config: {},
      searchInput: '',
      searchHintsComponent: '',
      searchFocused: false,
      hintListIndex: 0
    };
  },
  computed: {
    showClearButton: function showClearButton() {
      return this.searchInput && this.searchInput.length > 0;
    }
  },
  methods: {
    init: function init(config) {
      if (config) {
        this.config = config.settings;
        this.searchInput = config.searchTerm;
      }
    },
    clearSearchInput: function clearSearchInput(event) {
      event.preventDefault();
      this.searchInput = '';
    },
    searchFocus: function searchFocus() {
      this.searchFocused = true; // Check if the new search hints component should be used

      if (this.config.showResultsByContentType) {
        this.searchHintsComponent = 'searchhintsRedesign';
      } else {
        this.searchHintsComponent = 'searchhints';
      } // Unset the scroll padding applied to the HTML DOM element (accounts for the ad space),
      // as it causes a scrolling bug while typing in the search box


      var htmlElement = document.getElementsByTagName('html');
      if (htmlElement[0]) htmlElement[0].classList.add('unsetScrollPadding'); // Send off an analytics event

      GeneralMills.Analytics.raiseEvent({
        EventType: 'SearchIntent',
        Id: this.config.id,
        DataSourceId: this.config.dataSourceId,
        Language: this.config.language,
        Location: window.location.href
      });
    },
    searchBlur: function searchBlur(event) {
      // If blur is a click inside the hints container, keep focused
      if (event.relatedTarget == null || event.relatedTarget.closest(".searchBoxContainer") == null) {
        this.searchFocused = false;
        this.searchHintsComponent = ''; // Reapply the scroll padding for the HTML DOM element (accounts for the ad space)

        var htmlElement = document.getElementsByTagName('html');
        if (htmlElement[0]) htmlElement[0].classList.remove('unsetScrollPadding');
      }
    },
    searchClose: function searchClose(event) {
      event.target.blur();
    },
    searchListUp: function searchListUp() {
      if (!this.searchFocused) return;
      if (this.hintListIndex > 1) this.hintListIndex--;else this.hintListIndex = this.$children[0].hints.length;
    },
    searchListDown: function searchListDown() {
      if (!this.searchFocused) return;
      if (this.hintListIndex < this.$children[0].hints.length) this.hintListIndex++;else this.hintListIndex = 1;
    },
    searchEnter: function searchEnter(event) {
      event.preventDefault();

      if (this.searchFocused) {
        if (this.hintListIndex > 0 && this.$children[0].hints.length && this.$children[0].hints[this.hintListIndex - 1]) {
          this.$_setHref(this.$children[0].hints[this.hintListIndex - 1].Url);
        } else {
          this.searchClick();
        }
      }
    },
    searchClick: function searchClick() {
      var term = encodeURIComponent(this.searchInput.trim());

      if (term.length > 0) {
        setRecentSearchTermCookie(this.searchInput);
        var newUrl = this.config.autocompleteSearchPath + "?term=" + term + "&termDataSource=" + this.config.dataSourceId;
        this.$_setHref(newUrl);
      }
    },
    resetHints: function resetHints() {
      this.hintListIndex = 0;
    },
    $_setHref: function $_setHref(value) {
      window.location.assign(value);
    }
  }
};