var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "favoriteButton buttonAsLink",
      class: _vm.buttonIcon,
      attrs: { type: "button", "aria-label": _vm.buttonAriaLabel },
      on: { click: _vm.buttonClick }
    },
    [_vm._v("\n    " + _vm._s(_vm.buttonText) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }