export default {
  setCookie: function setCookie(name, value, expiredays, includeDomain) {
    var expdate = new Date(),
        cookie = name + "=" + window.escape(value); // set the expiration date

    if (!!expiredays) {
      expdate.setDate(expdate.getDate() + expiredays);
      cookie += ";expires=" + expdate.toUTCString();
    } // set the domain


    if (includeDomain) {
      cookie += ';domain=' + location.hostname.substring(location.hostname.indexOf('.'));
    } // ensure cookie applies to entire domain


    cookie += ";path=/"; // set the cookie

    document.cookie = cookie;
  },
  getCookie: function getCookie(name) {
    var start, end;

    if (document.cookie.length > 0) {
      start = document.cookie.indexOf(name + "=");

      if (start !== -1) {
        start = start + name.length + 1;
        end = document.cookie.indexOf(";", start);

        if (end === -1) {
          end = document.cookie.length;
        }

        return window.unescape(document.cookie.substring(start, end));
      }
    }

    return "";
  },
  removeCookie: function removeCookie(name) {
    this.setCookie(name, null, -1000);
  }
};