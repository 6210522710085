export default function loadExternalScript(path, id, callback, dataAttributes) {
  var existingScript = document.getElementById(id);

  if (!existingScript) {
    var script = document.createElement('script');
    script.src = path;
    script.id = id;

    for (var a in dataAttributes) {
      var dataAttr = dataAttributes[a];
      script.setAttribute(dataAttr.name, dataAttr.value);
    }

    document.head.appendChild(script);

    script.onload = function () {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
}
;